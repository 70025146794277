import MovieShotsLogo from '../assets/images/logo.png';

export const MovieShotsAvatar = ({ address, ensImage, size }) => {
  // const color = generateColorFromAddress(address);
  return ensImage ? (
    <img
      src={ensImage}
      width={size}
      height={size}
      style={{ borderRadius: 999 }}
    />
  ) : (
    <img
      src={MovieShotsLogo}
      alt="Avatar"
      width={size * 0.8}
      height={size * 0.8}
    />
    // <div
    //   style={{
    //     backgroundColor: color,
    //     borderRadius: 999,
    //     height: size,
    //     width: size,
    //   }}
    // >
    //   {/* Content */}
    // </div>
  );
};
