import React from 'react';
import { useConnect } from 'wagmi';
import { FaTimes } from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux';
import { useConnectModal } from '@rainbow-me/rainbowkit';
import { usePortal } from '../../hooks/portal';
import DecorativeElement from '../../assets/images/icons/M.svg';
import { Button } from '../../components/Button';
import { toggleSignInModalVisibility } from '../../redux/features/commonSlice';

export const SignIn = () => {
  const { connect, connectors, error, isLoading, pendingConnector } =
    useConnect();
  const rootEl = document.getElementById('root');
  const Portal = usePortal(document.querySelector('#modal-root'));
  const isModalVisible = useSelector(
    ({ common }) => common.isSignInModalVisible
  );

  const dispatch = useDispatch();
  const { openConnectModal } = useConnectModal();

  const onClose = () => {
    dispatch(toggleSignInModalVisibility(false));
  };

  const openEmailConnect = (connector) => {
    connect({ connector });
    setTimeout(() => {
      dispatch(toggleSignInModalVisibility(false));
    }, 2000);
  };

  const openWeb3Connect = () => {
    openConnectModal();
    dispatch(toggleSignInModalVisibility(false));
  };

  if (!isModalVisible) {
    rootEl.classList.remove('modal-open');
    return null;
  } else {
    rootEl.classList.add('modal-open');
  }

  return (
    <Portal>
      <div className="mint-modal-wrapper">
        <div className="mint-modal mint-modal-auto-height">
          <div className="mint-modal__decorative-element">
            <img className="decor" src={DecorativeElement} alt="m letter" />
          </div>
          <div className="mint-modal__header-btn-only">
            <button
              className="mint-modal__button mint-modal__button--close"
              onClick={onClose}
            >
              <FaTimes
                alt="close icon"
                className="mint-modal__button-icon"
                size={25}
              ></FaTimes>
            </button>
          </div>
          <div className="mint-modal__body">
            {connectors
              .filter((x) => x.id === 'magic')
              .map((connector) => (
                <Button
                  disabled={!connector.ready || isLoading}
                  key={connector.id}
                  onClick={() => openEmailConnect(connector)}
                  className="button button--golden button--full filled mint-modal__button_connect"
                >
                  {!isLoading && (
                    <span>Sing up with e-Mail</span>
                  )}
                  {!connector.ready && ' (unsupported)'}{' '}
                  {isLoading && connector.id === pendingConnector?.id && (
                    <span>Try connecting to {connector.name}</span>
                  )}
                </Button>
              ))}

            <Button
              onClick={openWeb3Connect}
              className="button button--golden button--full filled mint-modal__button_connect"
            >
              Connect Web3 Wallet
            </Button>

            <div className="mint-modal__input-error">
              {error && <p className="mint-modal__text">{error.message}</p>}
            </div>
          </div>
        </div>
      </div>
    </Portal>
  );
};
