import React, { Suspense } from 'react';
import { ToastContainer } from 'react-toastify';

import 'react-toastify/dist/ReactToastify.css';
import 'react-aspect-ratio/aspect-ratio.css';
import './assets/styles/index.scss';
import '@rainbow-me/rainbowkit/styles.css';

import { Router } from './routes/multi-page';
import { CookiesModal } from './components/Modals/CookiesAcceptance';
import { Spinner } from './components/Spinner';
import { MovieShotsAvatar } from './components/MovieShotsAvatar';
import { MovieShotsModalDarkTheme } from './components/MovieShotsModalDarkTheme';
import { MovieShotsModalLightTheme } from './components/MovieShotsModalLightTheme';
import { Disclaimer } from './components/Disclaimer';
import { usePortal } from './hooks/portal';
import { useIsMounted } from './hooks/useIsMounted';
import { SubscribeToNewsletter } from './components/Modals/SubscribeToNewsletter';
import { Header } from './sections/common/Header';
import { Footer } from './sections/common/Footer';
import { MovieShotsContextProvider } from './context/MovieShotsContext';
import {
  rainbowWallet,
  metaMaskWallet,
  coinbaseWallet,
  walletConnectWallet,
  ledgerWallet,
} from '@rainbow-me/rainbowkit/wallets';
import {
  RainbowKitProvider,
  getDefaultWallets,
  connectorsForWallets,
} from '@rainbow-me/rainbowkit';
import { rainbowTorusConnector } from './wallet-connectors/RainbowTorusConnector';
import { rainbowMagicConnector } from './wallet-connectors/RainbowMagicConnector';
import { alchemyProvider } from 'wagmi/providers/alchemy';
import { infuraProvider } from 'wagmi/providers/infura';
import { configureChains, createConfig, WagmiConfig } from 'wagmi';
import { publicProvider } from 'wagmi/providers/public';
import { goerli, mainnet } from 'wagmi/chains';
import { NewsletterBar } from './sections/common/NewsletterBar';
import { SignIn } from './components/Modals/SignIn';

export default function App() {
  const Portal = usePortal(document.querySelector('#toasters-root'));
  const isMounted = useIsMounted();
  const alchemyApiKey = process.env.REACT_APP_ALCHEMY_API_KEY;
  const infuraApiKey = process.env.REACT_APP_INFURA_API_KEY;
  const projectId = process.env.REACT_APP_WALLETCONNECT_PROJECT_ID;
  const IS_TESTNET = process.env.REACT_APP_IN_TESTNET_MODE === 'true';

  const { chains, publicClient, webSocketPublicClient } = configureChains(
    [mainnet, ...(IS_TESTNET ? [goerli] : [])],
    [
      publicProvider(),
      infuraProvider({ apiKey: infuraApiKey }),
      alchemyProvider({ apiKey: alchemyApiKey }),
    ]
  );

  // TODO ethers => viem
  const { wallets } = getDefaultWallets({
    appName: 'MovieShots',
    projectId,
    chains,
  });

  const appInfo = {
    appName: 'MovieShots',
    learnMoreUrl: '/help',
    disclaimer: Disclaimer,
  };

  const connectors = connectorsForWallets([
    ...wallets,
    {
      groupName: 'Other',
      wallets: [
        ledgerWallet({ chains, projectId }),
        rainbowMagicConnector({ chains }),
        rainbowTorusConnector({ chains }),
      ],
    },
  ]);

  const wagmiConfig = createConfig({
    autoConnect: true,
    connectors,
    publicClient,
    webSocketPublicClient,
  });

  const initCanceliationOfContextMenuOnVideoElements = () => {
    document.addEventListener('contextmenu', (event) => {
      const { target } = event;
      if (target.classList.contains('video')) {
        event.preventDefault();
      }
    });
  };

  initCanceliationOfContextMenuOnVideoElements();

  if (!isMounted) return null;

  return (
    <>
      <WagmiConfig config={wagmiConfig}>
        <RainbowKitProvider
          appInfo={appInfo}
          chains={chains}
          showRecentTransactions={true}
          avatar={MovieShotsAvatar}
          theme={MovieShotsModalLightTheme}
        >
          <MovieShotsContextProvider>
            <NewsletterBar />
            <Header />
            <Suspense fallback={<Spinner />}>
              <div className="main">
                <Router />
              </div>
            </Suspense>
            <Portal>
              <ToastContainer
                position="top-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
              />
            </Portal>
            <CookiesModal />
            <SubscribeToNewsletter />
            <SignIn />
            <Footer />
          </MovieShotsContextProvider>
        </RainbowKitProvider>
      </WagmiConfig>
    </>
  );
}
