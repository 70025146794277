import { MagicConnector } from './MagicConnector';
import MagicSvg from '../assets/images/icons/magic.svg';

const IS_TESTNET = process.env.REACT_APP_IN_TESTNET_MODE === 'true';

export const rainbowMagicConnector = ({ chains }) => ({
  id: 'magic',
  name: 'Magic',
  iconUrl: MagicSvg,
  iconBackground: '#fff',
  createConnector: () => {
    const connector = new MagicConnector({
      chains: chains,
      options: {
        apiKey: 'pk_live_4D6E0A9B0C66C627',
        magicSdkConfiguration: {
          network: {
            rpcUrl: IS_TESTNET ? process.env.REACT_APP_ALCHEMY_TEST_API_KEY_BERND : process.env.REACT_APP_ALCHEMY_MAIN_API_KEY_BERND,
            chainId: IS_TESTNET ? 5 : 1,
          },
        }
      },
    });
    return {
      connector,
    };
  },
});