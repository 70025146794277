import React from 'react';

import { Discord } from './Icons/Discord';
import { Instagram } from './Icons/Instagram';
import { Twitter } from './Icons/Twitter';
import { Mail } from './Icons/Mail';
import OpenSea from '../assets/images/icons/opensea.svg';
import Rarity from '../assets/images/icons/rarity.webp';
import NFTdroops from '../assets/images/icons/NFTdroops.webp';

export const SocialIcons = ({ className = '', section = 'header' }) => {
  const socialLinksInfo = [
    // {
    //   icon: <img src={Rarity} className="social-link" alt="rarity" />,
    //   url: 'https://raritysniper.com/nft-drops-calendar',
    //   target: '_blank',
    // },
    // {
    //   icon: (
    //     <img
    //       src="https://cdn.icy.tools/images/icy-tools-dark.svg"
    //       width="256"
    //       className="social-link"
    //       alt="as seen on icy.tools"
    //     />
    //   ),
    //   url: 'https://icy.tools/calendar',
    //   target: '_blank',
    // },

    // {
    //   icon: <img src={NFTdroops} className="social-link" alt="NFTdroops" />,
    //   url: 'https://nftdroops.com',
    //   target: '_blank',
    // },
    {
      icon: <Discord />,
      url: 'https://discord.gg/4yUAnUhhJt',
      target: '_blank',
    },
    {
      icon: <Twitter />,
      url: 'https://twitter.com/MovieShots',
      target: '_blank',
    },
    {
      icon: <Instagram />,
      url: 'https://www.instagram.com/officialmovieshots/',
      target: '_blank',
    },
    {
      icon: <img src={OpenSea} className="social-link" alt="opensea" />,
      url: 'https://opensea.io/MovieShots?tab=created',
      target: '_blank',
    },
    { icon: <Mail />, url: 'mailto:hello@movieshots.io', target: '_blank' },
  ];

  return (
    <ul className={`${className} ${section}__social-links`}>
      {socialLinksInfo.map(
        ({ target, url, icon }, index) =>
          section === 'header' &&
          index >= 0 && (
            <li className={`${section}__social-link-wrapper`} key={index}>
              <a
                href={url}
                target={target}
                rel="noopener noreferrer"
                className={`${section}__social-link`}
              >
                <span className={`${section}__social-link-icon`}>{icon}</span>
              </a>
            </li>
          )
      )}
      {socialLinksInfo.map(
        ({ target, url, icon }, index) =>
          section === 'footer' && (
            <li className={`${section}__social-link-wrapper`} key={index}>
              <a
                href={url}
                target={target}
                rel="noopener noreferrer"
                className={`${section}__social-link`}
              >
                <span className={`${section}__social-link-icon`}>{icon}</span>
              </a>
            </li>
          )
      )}
    </ul>
  );
};
