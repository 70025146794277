import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../api";

const initialState = {
  stats: {},
  loaded: false,
};

export const statisticsReducer = createSlice({
  name: "statistics",
  initialState,
  reducers: {
    updateStatistics: (state, action) => {
      state.stats = action.payload;
    },
    updateLoadingStatus: (state, action) => {
      state.isLoading = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchStatistics.rejected, (state) => {
        state.loaded = false;
      })
      .addCase(fetchStatistics.pending, (state) => {
        state.loaded = false;
      })
      .addCase(fetchStatistics.fulfilled, (state, action) => {
        state.stats = { ...action.payload };
        state.loaded = true;
      });
  },
});

export const fetchStatistics = createAsyncThunk(
  "statistics/fetchStatistics",
  async (number) => {
    return await api.getStatistics(number, /* TODO collectionName*/);
  }
);

export const { updateStatistics, updateMintedMovieshots } = statisticsReducer.actions;

export default statisticsReducer.reducer;
