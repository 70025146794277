import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    isAuthenticated: false,
    authToken: '',
}

export const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        toggleAuthStatus: (state, action) => {
            state.isAuthenticated = action.payload;
        },
        setAuthToken: (state, action) => {
            state.authToken = action.payload;
        }
    },
});

export const { toggleAuthStatus, setAuthToken } = authSlice.actions

export default authSlice.reducer;