import React from 'react';

export const Mail = () => {
    return  (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="36.164"
            height="25.478"
            viewBox="0 0 36.164 25.478"
        >
            <defs>
            <clipPath id="clip-path">
                <rect
                width="36.164"
                height="25.478"
                transform="translate(0 0)"
                fill="#fff"
                />
            </clipPath>
            </defs>
            <g clipPath="url(#clip-path)">
            <path
                d="M36.164,22.646a8.531,8.531,0,0,1-.636,1.507A3.017,3.017,0,0,1,33,25.475c-.07,0-.141,0-.212,0q-14.7,0-29.407,0A3.242,3.242,0,0,1,.65,24.2,3.164,3.164,0,0,1,0,22.22Q0,12.74.007,3.261A3.179,3.179,0,0,1,3.347,0Q9.436,0,15.526,0H32.788a3.153,3.153,0,0,1,3.052,1.829,6.9,6.9,0,0,1,.323,1ZM3.667,2.133c.066.082.091.12.122.151Q9.8,8.272,15.81,14.254a3.132,3.132,0,0,0,4.6-.052Q26.371,8.287,32.307,2.353a2.583,2.583,0,0,0,.174-.22Zm.2,21.193H32.305l-8.919-9.117c-.515.526-.988,1.014-1.467,1.5a5.313,5.313,0,0,1-7.67,0c-.481-.481-.953-.972-1.466-1.5L3.867,23.326M2.13,21.753l.077.053,8.84-9.022L2.13,3.706Zm31.889.113V3.624l-8.947,9.108,8.947,9.135"
                transform="translate(0 0)"
                fill="#fff"
            />
            </g>
    </svg>
  );
};