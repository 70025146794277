import React from "react";
import cn from "classnames";

export const Button = ({
  children,
  onClick,
  className,
  disabled = false,
  type = "light",
}) => {
  const classNames = cn({
    [className]: true,
    button: true,
    "button--light": type === "light",
    "button--dark": type === "dark",
    "button--golden": type === "golden",
    "button--green": type === "green",
    "button--red": type === "red",
    "button--golden-full": type === "green",
    "button--disabled": disabled,
  });
  return (
    <button className={classNames} onClick={onClick}>
      {children}
    </button>
  );
};
