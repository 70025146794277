import React, { useState } from 'react';
import cn from 'classnames';
import Checkbox from 'react-custom-checkbox';
import { FaTimes } from 'react-icons/fa';
import { FiCheck } from 'react-icons/fi';
import { CSSTransition } from 'react-transition-group';
import { useDispatch, useSelector } from 'react-redux';
import { usePortal } from '../../hooks/portal';
import DecorativeElement from '../../assets/images/icons/M.svg';
import { Loader } from '../../components/Loader';
import api from '../../api';
import { toggleSubscribeToNewsletterModalVisibility } from '../../redux/features/commonSlice';

export const SubscribeToNewsletter = () => {
  const statuses = {
    ERROR: 'error',
    PROCESSING: 'processing',
    SUBMIT: 'submit',
    SUCCESS: 'success',
  };
  const rootEl = document.getElementById('root');
  const Portal = usePortal(document.querySelector('#modal-root'));
  const isModalVisible = useSelector(
    ({ common }) => common.isSubscribeToNewsletterModalVisible
  );
  const dispatch = useDispatch();
  const [modalStatus, setModalStatus] = useState(statuses.SUBMIT);
  const [error, setError] = useState(null);
  const [errorMessage, setErrorMessage] = useState('');
  const [isChecked, setCheckStatus] = useState(false);
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');

  const getSubtitle = () => {
    switch (modalStatus) {
      case statuses.ERROR:
        return 'Error';
      case statuses.PROCESSING:
        return 'MovieShots Newsletter';
      case statuses.SUBMIT:
        return `MovieShots Newsletter`;
      case statuses.SUCCESS:
        return `MovieShots Newsletter`;
      default:
        return '';
    }
  };

  const getTitle = () => {
    switch (modalStatus) {
      case statuses.ERROR:
        return `There was an error when trying to susbcribe to our newsletter. Please try again.`;
      case statuses.PROCESSING:
        return 'Loading...';
      case statuses.SUBMIT:
        return `Be the first to know about our <strong>latest drops</strong> and <strong>exclusive perks for the MovieShot community</strong>`;
      case statuses.SUCCESS:
        return `You have <strong>successfully</strong> subscribed to our newsletter!`;
      default:
        return '';
    }
  };

  const onTryAgain = () => {
    setModalStatus(statuses.SUBMIT);
  };

  const onSubmit = async (evt) => {
    if (name.length < 1) {
      evt.preventDefault();
      setError(`Name is required!`);
    } else if (email.length < 1) {
      evt.preventDefault();
      setError(`E-mail is required!`);
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email)) {
      evt.preventDefault();
      setError(`Invalid e-mail address!`);
    } else {
      try {
        setModalStatus(statuses.PROCESSING);
        await api.subscribe(name, email);
        setModalStatus(statuses.SUCCESS);
        // setTimeout(() => onClose(), 3000);
      } catch (err) {
        if (err?.response?.data?.message) {
          setErrorMessage(err.response.data.message);
        }
        console.log(err);
        setModalStatus(statuses.ERROR);
      }
    }
  };

  const onClose = () => {
    setName('');
    setEmail('');
    setCheckStatus(false);
    setErrorMessage('');
    setModalStatus(statuses.SUBMIT);
    dispatch(toggleSubscribeToNewsletterModalVisibility(false));
  };

  const onInputFocus = () => {
    setError(null);
  };

  const onCheckboxClick = (value) => {
    setCheckStatus(value);
  };

  const onNameUpdate = (evt) => {
    setName(evt.target.value);
  };

  const onEmailUpdate = (evt) => {
    setEmail(evt.target.value);
  };

  const modalClass = cn({
    'mint-modal': true,
    'mint-modal--submit': modalStatus === statuses.SUBMIT,
    'mint-modal--error': modalStatus === statuses.ERROR,
    'mint-modal--processing': modalStatus === statuses.PROCESSING,
  });

  const submitButtonClass = cn({
    'mint-modal__button mint-modal__button--submit mint-modal__button--full': true,
    'mint-modal__button--disabled': !isChecked,
    'mint-modal__button--no-margin': error,
  });

  const inputClass = cn({
    'mint-modal__input mint-modal__input--gold mint-modal__input--full mint-modal__input--medium': true,
    'mint-modal__input--hasError': error,
  });

  if (!isModalVisible) {
    rootEl.classList.remove('modal-open');
    return null;
  } else {
    rootEl.classList.add('modal-open');
  }

  return (
    <Portal>
      <div className="mint-modal-wrapper">
        <div className={modalClass}>
          <div className="mint-modal__decorative-element">
            <img className="decor" src={DecorativeElement} alt="m letter" />
          </div>
          <div className="mint-modal__header">
            <h3
              className="mint-modal__subtitle"
              dangerouslySetInnerHTML={{
                __html: getSubtitle(),
              }}
            ></h3>
            <button
              className="mint-modal__button mint-modal__button--close"
              onClick={onClose}
            >
              <FaTimes
                alt="close icon"
                className="mint-modal__button-icon"
                size={25}
              ></FaTimes>
            </button>
          </div>
          <CSSTransition
            key={modalStatus}
            in={modalStatus}
            name="fade"
            timeout={200}
          >
            <div className="mint-modal__body">
              <h2
                className="mint-modal__title title"
                dangerouslySetInnerHTML={{
                  __html: getTitle(),
                }}
              ></h2>
              {modalStatus === statuses.SUBMIT && (
                <form className="mint-modal__form">
                  <label className="mint-modal__input-wrapper">
                    <div
                      className="mint-modal__input-content"
                      onClick={onInputFocus}
                    >
                      <input
                        type="text"
                        placeholder="Your Name"
                        className={inputClass}
                        onInput={onNameUpdate}
                        value={name}
                      />
                    </div>
                  </label>
                  <label className="mint-modal__input-wrapper">
                    <div
                      className="mint-modal__input-content"
                      onClick={onInputFocus}
                    >
                      <input
                        type="text"
                        placeholder="Your Email Address"
                        className={inputClass}
                        onInput={onEmailUpdate}
                        value={email}
                      />
                    </div>
                  </label>
                  {/* <p className="mint-modal__text">
                    Some Text
                  </p> */}
                  {error && (
                    <p className="mint-modal__input-error">
                      {error} Please check your input.
                    </p>
                  )}
                  <label className="mint-modal__checkbox-wrapper">
                    <Checkbox
                      icon={<FiCheck color="#000" size={14} />}
                      checked={isChecked}
                      onChange={onCheckboxClick}
                      borderColor="#000"
                      size={21}
                      style={{ cursor: 'pointer' }}
                      className="mint-modal__checkbox"
                    />
                    <p className="mint-modal__checkbox-text">
                      Confirm that you are human
                    </p>
                  </label>
                  <p className="mint-modal__consent">
                    Signing up indicates your consent to our{' '}
                    <a
                      href="/terms-and-conditions"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Terms and Conditions
                    </a>{' '}
                    and{' '}
                    <a
                      href="/data-protection"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Data Protection
                    </a>
                  </p>

                  <button className={submitButtonClass} onClick={onSubmit}>
                    Subscribe to our newsletter
                  </button>
                </form>
              )}
              {modalStatus === statuses.PROCESSING && (
                <>
                  <Loader className="mint-modal__loader" />
                  <div className="mint-modal__content">
                    <p className="mint-modal__text">
                      Subscribing to our newsletter.
                    </p>
                  </div>
                </>
              )}
              {modalStatus === statuses.SUCCESS && (
                <div className="mint-modal__content">
                  <p className="mint-modal__text">Thanks for signing up!</p>
                  <button
                    className="mint-modal__button mint-modal__button--full"
                    onClick={onClose}
                  >
                    Close
                  </button>
                </div>
              )}
              {modalStatus === statuses.ERROR && (
                <div className="mint-modal__content">
                  {errorMessage && (
                    <p className="mint-modal__text">{errorMessage}</p>
                  )}
                  <button className=" mint-modal__button" onClick={onTryAgain}>
                    Submit again
                  </button>
                </div>
              )}
            </div>
          </CSSTransition>
        </div>
      </div>
    </Portal>
  );
};
