import Torus from '@toruslabs/torus-embed';
import { Connector } from '@wagmi/core';
import { ethers } from 'ethers';
import {
    UserRejectedRequestError,
    createWalletClient,
    custom,
    getAddress,
} from 'viem';
import log from 'loglevel';

const IS_SERVER = typeof window === 'undefined';

export class TorusConnector extends Connector {
    ready = !IS_SERVER;

    id = 'torus';

    name = 'Torus';

    provider;

    torusInstance;

    torusOptions;

    network = {
        host: 'mainnet',
        chainId: 1,
        networkName: 'Ethereum Mainnet',
        blockExplorer: 'https://etherscan.io',
        ticker: 'ETH',
        tickerName: 'Ethereum',
    };

    constructor(config) {
        super(config);
        this.torusOptions = config.options;
        const chainId = config.options.chainId ? config.options.chainId : 1;
        const host = config.options.host ? config.options.host : 'mainnet';
        this.torusInstance = new Torus({
            buttonPosition: config.options.buttonPosition || 'bottom-left',
        });

        // set network according to chain details provided
        const chain = this.chains.find((x) => x.id === chainId);

        if (chain) {
            this.network = {
                host,
                chainId,
                networkName: chain.name,
                tickerName: chain.nativeCurrency?.name,
                ticker: chain.nativeCurrency?.symbol,
                blockExplorer: chain.blockExplorers.default?.url,
            };
        } else {
            log.warn(`ChainId ${chainId} not found in chain list`);
            this.emit('disconnect');
        }
    }

    async connect() {
        try {
            this.emit('message', {
                type: 'connecting',
            });

            if (!this.isAuthorized() || !this.provider) {
                // initialize torus embed
                if (!this.torusInstance.isInitialized) {
                    await this.torusInstance.init({
                        ...this.torusOptions.TorusParams,
                        network: this.network,
                    });
                } else if (this.torusOptions.TorusParams?.showTorusButton !== false) {
                    this.torusInstance.showTorusButton();
                }

                document.getElementById('torusIframe').style.zIndex =
                    '999999999999999999';
                await this.torusInstance.login();
            }

            // if there is a user logged in, return the user
            if (this.isAuthorized()) {
                const provider = await this.getProvider();

                if (provider.on) {
                    provider.on('accountsChanged', this.onAccountsChanged);
                    provider.on('chainChanged', this.onChainChanged);
                    provider.on('disconnect', this.onDisconnect);
                }

                const chainId = await this.getChainId();
                const account = await this.getAccount().catch(() => null);

                return {
                    provider,
                    chain: {
                        id: chainId,
                        unsupported: this.isChainUnsupported(chainId),
                    },
                    account,
                };
            }
            throw new Error('Failed to login, Please try again');
        } catch (error) {
            if (this.torusInstance.isInitialized) {
                this.torusInstance.hideTorusButton();
            }
            log.error('error while connecting', error);
            throw new UserRejectedRequestError('Something went wrong');
        }
    }

    async getAccount() {
        try {
            const provider = new ethers.providers.Web3Provider(
                await this.getProvider()
            );
            const signer = provider.getSigner();
            const account = await signer.getAddress();
            return account;
        } catch (error) {
            log.error('Error: Cannot get account:', error);
            throw error;
        }
    }

    async getProvider() {
        if (this.provider) {
            return this.provider;
        }
        this.provider = this.torusInstance.provider;
        return this.provider;
    }

    async getSigner() {
        try {
            const provider = new ethers.providers.Web3Provider(
                await this.getProvider()
            );
            const signer = provider.getSigner();
            return signer;
        } catch (error) {
            log.error('Error: Cannot get signer:', error);
            throw error;
        }
    }

    isAuthorized() {
        return this.torusInstance.isLoggedIn;
    }

    async getChainId() {
        try {
            const provider = await this.getProvider();
            if (!provider && this.network.chainId) {
                return this.normalizeChainId(this.network.chainId);
            } else if (provider) {
                const chainId = await provider.request({ method: 'eth_chainId' });
                if (chainId) {
                    return this.normalizeChainId(chainId);
                }
            }

            throw new Error('Chain ID is not defined');
        } catch (error) {
            log.error('Error: Cannot get Chain Id from the network.', error);
            throw error;
        }
    }

    async getWalletClient({ chainId }) {
        const [provider, account] = await Promise.all([
            this.getProvider(),
            this.getAccount(),
        ])
        const chain = this.chains.find((x) => x.id === chainId)
        if (!provider) throw new Error('provider is required.')
        return createWalletClient({
            account,
            chain,
            transport: custom(provider),
        })
    }

    async switchChain(chainId) {
        try {
            const chain = this.chains.find((x) => x.id === chainId);
            if (!chain) throw new Error(`Unsupported chainId: ${chainId}`);
            if (!this.isAuthorized()) throw new Error('Please login first');
            await this.torusInstance.setProvider({
                host: chain.rpcUrls.default,
                chainId,
                networkName: chain.name,
            });
            return chain;
        } catch (error) {
            log.error('Error: Cannot change chain', error);
            throw error;
        }
    }

    async disconnect() {
        this.provider.removeListener('accountsChanged', this.onAccountsChanged);
        this.provider.removeListener('chainChanged', this.onChainChanged);
        this.provider.removeListener('disconnect', this.onDisconnect);
        this.provider = null;
        this.torusInstance.hideTorusButton();
        await this.torusInstance.logout();
    }

    async openTopUp() {
        if (!this.isAuthorized() || !this.torusInstance) {
            return;
        }
        this.torusInstance.showWallet('topup');
    }

    isChainUnsupported(chainId) {
        return !this.chains.some((x) => x.id === chainId);
    }

    onAccountsChanged = (accounts) => {
        if (accounts.length === 0) {
            //this.emit('disconnect');
        } else this.emit('change', { account: getAddress(accounts[0]) });
    };

    onChainChanged = (chainId) => {
        const id = this.normalizeChainId(chainId);
        const unsupported = this.isChainUnsupported(id);
        this.emit('change', { chain: { id, unsupported } });
    };

    onDisconnect = () => {
        this.emit('disconnect');
    };

    normalizeChainId = (chainId) => {
        if (typeof chainId === 'object') return this.normalizeChainId(chainId.chainId)
        if (typeof chainId === 'string') return Number.parseInt(chainId, chainId.trim().substring(0, 2) === '0x' ? 16 : 10)
        if (typeof chainId === 'bigint') return Number(chainId)
        return chainId
    }
}
