import axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL;

const api = {
  getCollections: async (state = '-1') => {
    const { data } = await axios({
      method: 'get',
      url: `${API_URL}/collections/${state}`,
    });
    return data;
  },
  getCollectionsOnly: async (state = '-1') => {
    const { data } = await axios({
      method: 'get',
      url: `${API_URL}/collections/only/${state}`,
    });
    return data;
  },
  getCollectionById: async (id) => {
    const { data } = await axios({
      method: 'get',
      url: `${API_URL}/collections/${id}`,
    });
    return data;
  },
  getCollectionByName: async (name) => {
    const { data } = await axios({
      method: 'get',
      url: `${API_URL}/collections/token/${name}`,
    });
    return data;
  },
  getTagsById: async (collectionId, nftId) => {
    const { data } = await axios({
      method: 'get',
      url: `${API_URL}/nfts/tags/${collectionId}/${nftId}`,
    });
    return data;
  },
  getUserInfo: async () => {
    const { data } = await axios({
      method: 'get',
      url: 'https://ipapi.co/json/',
    });
    return data;
  },
  sendTransactionInfo: async (info) => {
    try {
      const { data } = await axios({
        method: 'post',
        url: `${API_URL}/transactions`,
        data: info,
      });
      return data;
    } catch (err) { }
  },
  getWhitelistAddress: async () => {
    const data = axios({
      method: 'GET',
      url: `${API_URL}/collections/211/whitelist`,
    });
    return data;
  },
  getSilhouetteslist: async (id) => {
    const data = axios({
      method: 'GET',
      url: `${API_URL}/silhouetteAirdrops/${id}`,
    });
    return data;
  },
  getSilhouettesAirdrop: async (id, address) => {
    const data = axios({
      method: 'GET',
      url: `${API_URL}/silhouetteAirdrops/${id}/${address}`,
    });
    return data;
  },
  updateSilhouettesAirdrop: async (id, address, minted) => {
    const { data } = await axios({
      method: 'put',
      url: `${API_URL}/silhouetteAirdrops/${id}/${address}`,
      data: minted,
    });
    return data;
  },
  updateMovieShotOpenStatus: async (id, collectionSlug = 'MSHOT-LR98') => {
    const { data } = await axios({
      method: 'get',
      url: `${API_URL}/nfts/open/${collectionSlug}/${id}`,
    });
    return data;
  },
  updateMovieShotOwner: async (id, collectionSlug, ownerAddress) => {
    const { data } = await axios({
      method: 'get',
      url: `${API_URL}/nfts/owner/${collectionSlug}/${id}/${ownerAddress}`,
    });
    return data;
  },
  getMovieshotInfoById: async (id, collectionSlug = 'MSHOT-LR98') => {
    const { data } = await axios({
      method: 'get',
      url: `${API_URL}/nfts/byTokenAndNftId`,
      params: {
        token: collectionSlug,
        id: id,
      },
    });

    return data;
  },
  getMovieshotsByAddress: async (address, collectionSlug = null) => {
    const { data } = await axios({
      method: 'get',
      url: `${API_URL}/nfts/byAddress`,
      params: {
        owner: address,
        token: collectionSlug,
      },
    });

    return data;
  },
  getStatistics: async (mintedNumber = 0, collectionSlug = 'MSHOT-LR98') => {
    const { data } = await axios({
      method: 'get',
      url: `${API_URL}/collections/${collectionSlug}/statistics/${mintedNumber}`,
    });

    return data;
  },
  getLeaderboard: async (page = 1, take = 20, type = 'movieshots', order = 'DESC') => {
    const { data } = await axios({
      method: 'get',
      url: `${API_URL}/nfts/leaderboard`,
      params: {
        type,
        order,
        page,
        take
      },
    });

    return data;
  },
  getUsersList: async () => {
    const { data } = await axios({
      method: 'get',
      url: `${API_URL}/users`,
    });

    return data;
  },
  createUser: async (user) => {
    const { data } = await axios({
      method: 'post',
      url: `${API_URL}/users`,
      data: user,
    });
    return data;
  },
  deleteUser: async (id) => {
    const { data } = await axios({
      method: 'delete',
      url: `${API_URL}/users/${id}`,
    });

    return data;
  },
  getNonce: async (wallet) => {
    const { data } = await axios({
      method: 'get',
      url: `${API_URL}/users/nonce/${wallet}`,
    });
    return data;
  },
  getAuthToken: async (nonce, signature) => {
    const { data } = await axios({
      method: 'post',
      url: `${API_URL}/auth/login`,
      data: {
        nonce,
        signature,
      },
    });
    return data;
  },
  updateUser: async (id, user) => {
    const { data } = await axios({
      method: 'put',
      url: `${API_URL}/users/${id}`,
      data: user,
    });
    return data;
  },
  getVotings: async () => {
    const { data } = await axios({
      method: 'get',
      url: `${API_URL}/votes`,
    });
    return data;
  },
  getVotingDetails: async (id) => {
    const { data } = await axios({
      method: 'get',
      url: `${API_URL}/votes/getDetails/${id}`,
    });
    return data;
  },
  getWeb3Data: async () => {
    const { data } = await axios({
      method: 'get',
      url: `${API_URL}/web3/1`,
    });
    return data;
  },
  getAvailableVotingPoints: async (userWallet, voteId) => {
    const { data } = await axios({
      method: 'get',
      url: `${API_URL}/votes/available/${userWallet}/${voteId}`,
    });
    return data;
  },
  voteForFilm: async (vote) => {
    const { data } = await axios({
      method: 'post',
      url: `${API_URL}/votes/choose`,
      data: vote,
    });
    return data;
  },
  subscribe: async (name, email) => {
    const { data } = await axios({
      method: 'post',
      url: `${API_URL}/users/subscribe`,
      data: { name, email },
    });
    return data;
  }
};

export default api;
