import React, { lazy, useMemo } from 'react';
import { Routes, Route } from 'react-router-dom';

export const navigation = [
  { path: '/concept', name: 'Concept' },
  { path: '/movies', name: 'Movies' },
  { path: '/utility', name: 'Utility' },
  { path: '/team', name: 'Team' },
  { path: '/help', name: 'Help' },
  { path: '/press', name: 'Press' },
];

export const routes = [
  { path: '/', name: 'Index' },
  { path: '/not-found', name: 'NotFound' },
  { path: '/airdrops', name: 'Airdrops' },
  { path: '/nosferatu', name: 'FreeClaim' },
  { path: '/utility', name: 'Utility' },
  { path: '/press', name: 'Press' },
  { path: '/concept', name: 'Concept' },
  { path: '/help', name: 'Help' },
  { path: '/imprint', name: 'Imprint' },
  { path: '/movie/:movie', name: 'Movie' },
  { path: '/movies', name: 'Movies' },
  { path: '/team', name: 'Team' },
  { path: '/terms-and-conditions', name: 'TermsAndConditions' },
  { path: '/data-protection', name: 'DataProtection' },
  { path: '/voting', name: 'Voting' },
  { path: '/wallet', name: 'Wallet' },
  { path: '/leaderboard', name: 'Leaderboard' },
  { path: '/watch', name: 'Redirect', url: 'https://app.beem.xyz/u/MovieShots-A07178/videos' },
  {
    path: '/movieshot/:collection/multiple',
    name: 'Overview',
    needsAuthentication: true,
  },
  {
    path: '/movieshot/:collection/:id',
    name: 'Overview',
  },
  { path: '/collection/:address', name: 'PublicCollection' },
  { path: '/collection', name: 'Collection', needsAuthentication: true },
];

export const Router = () => {
  const routeList = useMemo(
    () =>
      routes.map(({ path, name, url }) => {
        const Component = lazy(() => import(`../pages/${name}`));

        if (name === 'Redirect') {
          return (
            <Route
              key={`route-${name}`}
              path={path}
              element={<Component url={url} />}
            />)
        }

        return (
          <Route key={`route-${name}`} path={path} element={<Component />} />
        );
      }),
    []
  );

  return <Routes>{routeList}</Routes>;
};
