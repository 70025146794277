import { createSlice } from "@reduxjs/toolkit";
import { statuses } from "../../constants/mintStatuses";

const initialState = {
  isMintModalVisible: false,
  isCookiesModalVisible: false,
  isSubscribeToNewsletterModalVisible: false,
  isSignInModalVisible: false,
  isPageLoading: false,
  whitelistMintAllowed: false,
  freeMintAllowed: false,
  isMetaMaskModalVisible: false,
  mintModalMode: statuses.SUBMIT,
  userAccount: null,
  discountGroup: null,
  selectedChain: null,
  currentChain: null,
  areChainsSame: true,
  userInfo: null,
  selectedMovie: null
};

export const commonReducer = createSlice({
  name: "common",
  initialState,
  reducers: {
    setChainsEqualityStatus: (state, action) => {
      state.areChainsSame = action.payload;
    },
    setUserInfo: (state, action) => {
      state.userInfo = action.payload;
    },
    setChain: (state, action) => { 
      state.currentChain = action.payload;
    },
    setAccount: (state, action) => { 
      state.userAccount = action.payload;
    },
    setDiscountGroup: (state, action) => { 
      state.discountGroup = action.payload;
    },
    setFreeMintAllowanceStatus: (state, actions) => {
      state.freeMintAllowed = actions.payload;
    },
    setWhitelistMintAllowanceStatus: (state, actions) => {
      state.whitelistMintAllowed = actions.payload;
    },
    updateMintModalMode: (state, actions) => {
      state.mintModalMode = actions.payload;
    },
    setPageLoadingStatus: (state, action) => {
      state.isPageLoading = action.payload;
    },
    setSelectedMovie: (state, action) => {
      state.selectedMovie = action.payload;
    },
    toggleMetaMaskModalVisibility: (state, action) => {
      state.isMetaMaskModalVisible = action.payload;
    },
    toggleMintModalVisibility: (state, action) => {
      state.isMintModalVisible = action.payload;
    },
    toggleCookiesModalVisibility: (state, action) => {
      state.isCookiesModalVisible = action.payload;
    },
    toggleSubscribeToNewsletterModalVisibility: (state, action) => {
      state.isSubscribeToNewsletterModalVisible = action.payload;
    },
    toggleSignInModalVisibility: (state, action) => {
      state.isSignInModalVisible = action.payload;
    },
  },
});

export const {
  toggleMintModalVisibility,
  toggleMetaMaskModalVisibility,
  toggleCookiesModalVisibility,
  toggleSubscribeToNewsletterModalVisibility,
  toggleSignInModalVisibility,
  setPageLoadingStatus,
  setProviderState,
  updateMintModalMode,
  setWhitelistMintAllowanceStatus,
  setFreeMintAllowanceStatus,
  setAccount,
  setDiscountGroup,
  setChain,
  setAddressInitializationStatus,
  setChainsEqualityStatus,
  setChainNotificationStatus,
  setSelectedMovie,
  setUserInfo,
} = commonReducer.actions;

export default commonReducer.reducer;
