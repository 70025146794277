import { configureStore } from '@reduxjs/toolkit';
import authReducer from '../features/authSlice';
import statsReducer from '../features/statisticsSlice';
import commonReducer from '../features/commonSlice';

export const store = configureStore({
    reducer: {
        auth: authReducer,
        statistics: statsReducer,
        common: commonReducer,
    }
})