import React, { useState, useEffect } from 'react';
import { useMediaQuery } from 'beautiful-react-hooks';
import cn from 'classnames';
import Sheet from 'react-modal-sheet';
import styled from 'styled-components';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { MobileMenu } from '../../components/MobileMenu';
import { ProfileDropdown } from '../../components/ProfileDropdown';
import { SocialIcons } from '../../components/SocialIcons';
import { Logo } from '../../components/Logo';
import { Button } from '../../components/Button';
import ArrowDown from '../../assets/images/icons/arrow-down.svg';
import { scrollTo } from '../../helpers/scroll';
import api from '../../api';
import {
  setAccount,
  setDiscountGroup,
  setWhitelistMintAllowanceStatus,
  setFreeMintAllowanceStatus,
  toggleSignInModalVisibility,
  setUserInfo,
} from '../../redux/features/commonSlice';
import { toggleAuthStatus } from '../../redux/features/authSlice';
import { navigation } from '../../routes/multi-page';
import { useDisconnect, useAccount } from 'wagmi';
import { ConnectButton } from '@rainbow-me/rainbowkit';

export const Header = ({ isDocument = false }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { elemScrollTo } = useParams();
  const [isOpen, setOpen] = useState(false);
  const [isBurgerOpen, setBurgerOpen] = useState(false);
  const [isTorus, setIsTorus] = useState(false);
  const [isMagic, setIsMagic] = useState(false);
  const isMobileView = useMediaQuery('(max-width: 991px)');
  const { disconnect } = useDisconnect();

  const CustomSheet = styled(Sheet)`
    .react-modal-sheet-backdrop {
      /* custom styles */
    }
    .react-modal-sheet-container {
      background-color: #1c1c1c !important;
    }
    .react-modal-sheet-header {
      /* custom styles */
    }
    .react-modal-sheet-drag-indicator {
      /* custom styles */
    }
    .react-modal-sheet-content {
      /* custom styles */
    }
  `;

  const onWalletConnected = async (data) => {
    console.log(`Fetch discount group for ${data.address}`);
    await fetchDiscountGroup(data.address);
    console.log(`FINISHED`);
    console.log(`${data.address} connected`);
    console.log('Connector:', data.connector);
  };

  const onWalletDisconnected = async () => {
    console.log(`Wallet disconnected`);
    disconnect();
  };

  const mainAccount = useAccount({
    onConnect: (data) => onWalletConnected(data),
    onDisconnect: () => onWalletDisconnected(),
  });
  const { connector: activeConnector } = useAccount();

  const fetchDiscountGroup = async (address) => {
    const discountGroup1 = await api.getSilhouettesAirdrop(224, address);
    const discountGroup2 = await api.getSilhouettesAirdrop(225, address);
    let discountGroup = null;

    if (discountGroup2.data.address === address.toLowerCase()) {
      discountGroup = 'group2';
    } else if (discountGroup1.data.address === address.toLowerCase()) {
      discountGroup = 'group1';
    } else {
      discountGroup = 'none';
    }

    dispatch(setDiscountGroup(discountGroup));
    console.log(`Account ${address} is in discount group: ${discountGroup}`);
  };

  if (elemScrollTo) {
    scrollTo(elemScrollTo);
  }

  const burgerClasses = cn({
    header__burger: true,
    'header__burger--open': isBurgerOpen,
  });
  const headerClasses = cn({
    'header container': true,
    'header--burgerOpen': isBurgerOpen,
  });

  // const getUserInfo = async () => {
  //   const { country_name: country, city, ip } = await api.getUserInfo();
  //   dispatch(setUserInfo({ country, city, ip }));
  // };

  // useEffect(() => {
  //   if (!userInfo) {
  //     getUserInfo();
  //   }
  // }, [userInfo]);

  useEffect(() => {
    const handleConnectorUpdate = async ({ account, chain }) => {
      if (account) {
        console.log('Account changed to', account);
        await fetchDiscountGroup(account);
      } else if (chain) {
        console.log('New chain', chain);
      }
    };

    if (activeConnector) {
      activeConnector.on('change', handleConnectorUpdate);
    }

    return () => activeConnector?.off('change', handleConnectorUpdate);
  }, [activeConnector]);

  useEffect(() => {
    if (mainAccount.status === 'connected') {
      dispatch(setAccount(mainAccount.address));
      dispatch(toggleAuthStatus(true));
    } else if (mainAccount.status === 'disconnected') {
      dispatch(setAccount(null));
      dispatch(toggleAuthStatus(false));
      dispatch(setWhitelistMintAllowanceStatus(false));
      dispatch(setFreeMintAllowanceStatus(false));
    }
    if (mainAccount?.connector?.id === 'torus') {
      setIsTorus(true);
    } else if (mainAccount?.connector?.id === 'magic') {
      setIsMagic(true);
    }
  }, [mainAccount]);

  const closeBurger = () => {
    setBurgerOpen(false);
  };

  const openSignIn = () => {
    dispatch(toggleSignInModalVisibility(true));
  };

  const onBurgerClick = () => {
    document.body.classList.toggle('burger-open');
    if (isBurgerOpen) {
      document.querySelector('.main').style.display = 'block';
    } else {
      document.querySelector('.main').style.display = 'none';
    }
    setBurgerOpen(!isBurgerOpen);
  };

  return (
    <div name="header" className="header-wrapper">
      <header className={headerClasses}>
        {isMobileView && !isDocument ? (
          <>
            <div className="header__actions">
              <button className={burgerClasses} onClick={onBurgerClick}>
                <div
                  className="header__burger-icon"
                  role="img"
                  aria-label="Burger menu wrapper"
                >
                  <div
                    className="header__burger-line"
                    role="img"
                    aria-label="Burger menu line"
                  ></div>
                  <div
                    className="header__burger-line"
                    role="img"
                    aria-label="Burger menu line"
                  ></div>
                  <div
                    className="header__burger-line"
                    role="img"
                    aria-label="Burger menu line"
                  ></div>
                </div>
              </button>
              {isBurgerOpen && (
                <MobileMenu closeBurger={closeBurger} isDocument={isDocument} />
              )}
            </div>
            <ConnectButton.Custom>
              {({
                account,
                chain,
                openAccountModal,
                openChainModal,
                mounted,
              }) => {
                const ready = mounted !== 'loading';
                const connected = ready && account && chain;

                return (
                  <div
                    {...(!ready && {
                      'aria-hidden': true,
                      style: {
                        opacity: 0,
                        pointerEvents: 'none',
                        userSelect: 'none',
                      },
                    })}
                  >
                    {(() => {
                      if (!connected) {
                        return (
                          <Button
                            className="header__button filled"
                            type="golden"
                            onClick={openSignIn}
                          >
                            <span>Sign In</span>
                          </Button>
                        );
                      }

                      if (chain.unsupported) {
                        return (
                          <Button
                            className="header__button filled"
                            type="red"
                            onClick={openChainModal}
                          >
                            <span>Wrong network</span>
                          </Button>
                        );
                      }

                      return (
                        <>
                          <div
                            className="header__button filled button button--golden"
                            onClick={() => setOpen(true)}
                          >
                            <span className="address">
                              {account.displayName}
                            </span>
                          </div>
                          <CustomSheet
                            isOpen={isOpen}
                            onClose={() => setOpen(false)}
                            detent="content-height"
                          >
                            <Sheet.Container>
                              <Sheet.Header />
                              <Sheet.Content>
                                <ul className="header-mobile-menu">
                                  <li>
                                    <button
                                      onClick={() => navigate('/collection')}
                                    >
                                      My Collection
                                    </button>
                                  </li>
                                  {isMagic || isTorus ? (
                                    <li>
                                      <button
                                        onClick={() =>
                                          mainAccount?.connector?.openTopUp()
                                        }
                                      >
                                        Buy ETH
                                      </button>
                                    </li>
                                  ) : (
                                    <li>
                                      <span className="item-hidden"></span>
                                    </li>
                                  )}
                                  <li>
                                    <button onClick={openAccountModal}>
                                      Account Info
                                    </button>
                                  </li>
                                  <li>
                                    <button onClick={disconnect}>
                                      Sign out
                                    </button>
                                  </li>
                                </ul>
                              </Sheet.Content>
                            </Sheet.Container>
                            <Sheet.Backdrop />
                          </CustomSheet>
                        </>
                      );
                    })()}
                  </div>
                );
              }}
            </ConnectButton.Custom>
            <Logo className="header__logo" isMobile="true" />
            {isBurgerOpen && <SocialIcons section="header" />}
          </>
        ) : (
          <>
            <Logo className="header__logo" />
            <div className="header__actions">
              <nav className="header__navigation navigation">
                <ul className="navigation__list">
                  {navigation.map(({ path, name }) => (
                    <li className="navigation__list-item" key={`link-${name}`}>
                      <Link to={path} className="navigation__list-button link">
                        {name}
                      </Link>
                    </li>
                  ))}
                </ul>
              </nav>
              <SocialIcons section="header" />
              {isDocument && (
                <Link
                  to="/"
                  className="header__button header__button--back button button--light"
                >
                  Back
                </Link>
              )}
              <ConnectButton.Custom>
                {({
                  account,
                  chain,
                  openAccountModal,
                  openChainModal,
                  mounted,
                }) => {
                  const ready = mounted !== 'loading';
                  const connected = ready && account && chain;

                  return (
                    <div
                      {...(!ready && {
                        'aria-hidden': true,
                        style: {
                          opacity: 0,
                          pointerEvents: 'none',
                          userSelect: 'none',
                        },
                      })}
                    >
                      {(() => {
                        if (!connected) {
                          return (
                            <Button
                              className="header__button filled"
                              type="golden"
                              onClick={openSignIn}
                            >
                              <span>Sign In</span>
                            </Button>
                          );
                        }

                        if (chain.unsupported) {
                          return (
                            <Button
                              className="header__button filled"
                              type="red"
                              onClick={openChainModal}
                            >
                              <span>Wrong network</span>
                            </Button>
                          );
                        }

                        return (
                          <div>
                            <ProfileDropdown
                              trigger={
                                <div className="header__button filled button button--golden">
                                  <span className="address">
                                    {account.displayName}
                                  </span>
                                  <img
                                    className="dropdown-arrow"
                                    src={ArrowDown}
                                    alt="Arrow down"
                                  />
                                </div>
                              }
                              menu={[
                                <button onClick={() => navigate('/collection')}>
                                  My Collection
                                </button>,
                                isMagic || isTorus ? (
                                  <button
                                    onClick={() =>
                                      mainAccount?.connector?.openTopUp()
                                    }
                                  >
                                    Buy ETH
                                  </button>
                                ) : (
                                  <span className="item-hidden"></span>
                                ),
                                <button onClick={openAccountModal}>
                                  Account Info
                                </button>,
                                <button onClick={disconnect}>Sign out</button>,
                              ]}
                              account={account}
                            />
                          </div>
                        );
                      })()}
                    </div>
                  );
                }}
              </ConnectButton.Custom>
            </div>
          </>
        )}
      </header>
    </div>
  );
};
