import React from 'react';
import { Link } from 'react-router-dom';
import { navigation } from '../routes/multi-page';

export const MobileMenu = ({ closeBurger }) => {
  const onAnchorClick = (_event) => {
    closeBurger();
    document.body.classList.toggle('burger-open');
  };

  return (
    <nav className="header__navigation navigation--mobile">
      <ul className="navigation__list">
        {navigation.map(({ path, name }) => (
          <li className="navigation__list-item" key={`link-${name}-mobile`}>
            <Link
              to={path}
              className="navigation__list-button link"
              onClick={onAnchorClick}
            >
              {name}
            </Link>
          </li>
        ))}
      </ul>
    </nav>
  );
};
