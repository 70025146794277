import { Magic } from 'magic-sdk';
import {
    Connector,
} from '@wagmi/core';
import { ethers } from 'ethers';
import {
    UserRejectedRequestError,
    createWalletClient,
    custom,
    getAddress,
} from 'viem';

const IS_SERVER = typeof window === 'undefined';

// MagicConnectConnector class extends the base wagmi Connector class
export class MagicConnector extends Connector {
    ready = !IS_SERVER;
    id = 'magic';
    name = 'Magic';

    provider;
    magic;

    // Constructor initializes the Magic instance
    // allows connectUI modal to display faster when connect method is called
    constructor(config) {
        super(config);
        this.initializeMagicInstance();
    }

    // Private method to initialize the Magic instance
    initializeMagicInstance() {
        const { apiKey, magicSdkConfiguration } = this.options;
        if (typeof window !== 'undefined') {
            this.magic = new Magic(apiKey, {
                ...magicSdkConfiguration,
                //extensions: [new ConnectExtension()],
            });

            this.provider = this.magic.rpcProvider;
        }
    }

    // Connect method attempts to connects to wallet using Magic Connect modal
    async connect() {
        try {
            await this.magic.wallet.connectWithUI();
            const provider = await this.getProvider();
            const chainId = await this.getChainId();

            this.registerProviderEventListeners(provider);

            const account = await this.getAccount();

            return {
                account,
                chain: {
                    id: chainId,
                    unsupported: false,
                },
                provider,
            };
        } catch (error) {
            throw new UserRejectedRequestError(error);
        }
    }

    // Private method to register event listeners for the provider
    registerProviderEventListeners(
        provider) {
        if (provider.on) {
            provider.on('accountsChanged', this.onAccountsChanged);
            provider.on('chainChanged', this.onChainChanged);
            provider.on('disconnect', this.onDisconnect);
        }
    }

    // Disconnect method attempts to disconnect wallet from Magic
    async disconnect() {
        try {
            await this.magic.wallet.disconnect();
            this.emit('disconnect');
        } catch (error) {
            console.error('Error disconnecting from Magic SDK:', error);
        }
    }

    // Get connected wallet address
    async getAccount() {
        const signer = await this.getSigner();
        const account = await signer.getAddress();
        return getAddress(account);
    }

    // Get chain ID
    async getChainId() {
        const networkOptions = this.options.magicSdkConfiguration?.network;
        if (typeof networkOptions === 'object') {
            const chainID = networkOptions.chainId;
            if (chainID) return this.normalizeChainId(chainID);
        }
        throw new Error('Chain ID is not defined');
    }

    async getWalletClient({ chainId }) {
        const [provider, account] = await Promise.all([
            this.getProvider(),
            this.getAccount(),
        ])
        const chain = this.chains.find((x) => x.id === chainId)
        if (!provider) throw new Error('provider is required.')
        return createWalletClient({
            account,
            chain,
            transport: custom(provider),
        })
    }

    async openTopUp() {
        await this.magic.wallet.showUI();
    }

    // Get the Magic Instance provider
    async getProvider() {
        if (!this.provider) {
            this.provider = this.magic.rpcProvider;
        }
        return this.provider;
    }

    // Get the Magic Instance signer
    async getSigner() {
        const provider = new ethers.providers.Web3Provider(
            await this.getProvider()
        );
        return provider.getSigner();
    }

    // Autoconnect if account is available
    async isAuthorized() {
        try {
            const walletInfo = await this.magic.wallet.getInfo();
            return !!walletInfo;
        } catch {
            return false;
        }
    }

    // Event handler for accountsChanged event
    onAccountsChanged = (accounts) => {
        if (accounts.length === 0) this.emit('disconnect');
        else this.emit('change', { account: getAddress(accounts[0]) });
    };

    // Event handler for chainChanged event
    onChainChanged = (chainId) => {
        console.log(chainId);
        const id = this.normalizeChainId(chainId);
        const unsupported = this.isChainUnsupported(id);
        this.emit('change', { chain: { id, unsupported } });
    };

    // Event handler for disconnect event
    onDisconnect = () => {
        this.emit('disconnect');
    };

    normalizeChainId = (chainId) => {
        if (typeof chainId === 'object') return this.normalizeChainId(chainId.chainId)
        if (typeof chainId === 'string') return Number.parseInt(chainId, chainId.trim().substring(0, 2) === '0x' ? 16 : 10)
        if (typeof chainId === 'bigint') return Number(chainId)
        return chainId
    }
}