import React, { useState, useEffect } from 'react';
import cn from 'classnames';
import { useMovieShotsContext } from '../context/MovieShotsContext';

export const ProfileDropdown = ({ trigger, menu, account }) => {
  const [open, setOpen] = useState(false);
  const [coingeckoEthPrice, setCoingeckoEthPrice] = useState(null);
  const { isLoading, ethPrice } = useMovieShotsContext();
  const dropdownClass = cn({
    dropdown: true,
    'dropdown-open': open,
  });

  const handleOpen = () => {
    setOpen(!open);
  };

  useEffect(() => {
    if (!isLoading && ethPrice?.usd) {
      setCoingeckoEthPrice(ethPrice);
    }
  }, [isLoading, ethPrice]);

  return (
    <div className={dropdownClass}>
      {React.cloneElement(trigger, {
        onClick: handleOpen,
      })}
      {open ? (
        <div className="dropdown-menu">
          <div className="dropdown-header">
            <div>
              {Number(account.balanceFormatted).toFixed(2)} <span>ETH</span>
            </div>
            <div>
              {Number(account.balanceFormatted * coingeckoEthPrice?.usd).toFixed(2)}{' '}
              <span>USD</span>
            </div>
          </div>
          <ul>
            {menu.map((menuItem, index) => (
              <li key={index}>
                {React.cloneElement(menuItem, {
                  onClick: () => {
                    menuItem.props.onClick();
                    setOpen(false);
                  },
                })}
              </li>
            ))}
          </ul>
        </div>
      ) : null}
    </div>
  );
};
