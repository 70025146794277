import React from "react";
import { useDispatch, useSelector } from 'react-redux';

import { usePortal } from "../../hooks/portal";
import { Logo } from "../Logo";
import { Button } from "../Button";
import { toggleCookiesModalVisibility } from "../../redux/features/commonSlice";


export const CookiesModal = () => {
  const Portal = usePortal(document.querySelector("#modal-root"));
  const isCookiesModalVisible = useSelector(({ common }) => common.isCookiesModalVisible);
  const dispatch = useDispatch();

  const onAgree = () => {
    dispatch(toggleCookiesModalVisibility(false));
  }

  if (!isCookiesModalVisible) {
      return null;
  }

  return (
    <Portal>
      <div className="cookies-modal">
        <div className="cookies-modal__logo-wrapper">
            <Logo className="cookies-modal__logo" />
        </div>
        <div className="cookies-modal__content">
            <p className="cookies-modal__text">
            To improve our website and to be able to personalize ads for you, we
            use cookies. By clicking on the button you agree to the use of cookies.
            </p>
            <Button type="green" className="cookies-modal__button" onClick={onAgree}>
                Yes, I agree
            </Button>
        </div>
      </div>
    </Portal>
  );
};
