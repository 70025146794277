import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import LogoDefault from '../assets/images/icons/logo.svg';
import LogoM from '../assets/images/icons/logo_M.svg';
import LogoWhite from '../assets/images/icons/logo-white.svg';
import { animateScroll as scroll } from 'react-scroll';

export const Logo = ({
  mode = 'default',
  className = '',
  isMobile = false,
}) => {
  const image = mode === 'default' ? LogoDefault : LogoWhite;
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const onClick = () => {
    if (pathname !== '/') {
      navigate('/');
    }
    scroll.scrollToTop();
  };

  return (
    <div className={`logo-wrapper ${className}`} onClick={onClick}>
      <img
        className="logo"
        src={isMobile ? LogoM : image}
        alt="movieshot logo"
      />
    </div>
  );
};
