// import { TorusConnector } from '@toruslabs/torus-wagmi-connector';
import { TorusConnector } from './TorusConnector';
import TorusSvg from '../assets/images/icons/torus-icon.svg';
import Email from '../assets/images/icons/email.png';

export const rainbowTorusConnector = ({ chains }) => ({
  id: 'torus',
  name: 'Torus',
  iconUrl: TorusSvg,
  iconBackground: '#fff',
  downloadUrls: {
    android: 'https://app.tor.us/',
    ios: 'https://app.tor.us/',
    browserExtension: 'https://app.tor.us/',
    qrCode: 'https://app.tor.us/',
  },
  createConnector: () => {
    const connector = new TorusConnector({
      chains: chains,
      options: {
        chainId: chains[0].chainId,
        host: chains[0].host,
        buttonPosition: 'top-right',
        // apiKey: ''
      },
    });
    return {
      connector,
    };
  },
});

// const torusConnector = new TorusConnector({
//   chains,
//   options: {
//     apiKey:
//       'BCxGuITJ9PYv6DbFxTp8Fw6WCmxpPHuDVR0NZuj8pgbAnIg3DSt7R76JaAQoMMEeA2ekmezvgXyeBYSpz1FflvY',
//     buttonPosition: 'top-right',
//     TorusParams: {
//       enableLogging: true,
//     },

//     chainId: 1,
//     host: 'mainnet',
//   },
// });
