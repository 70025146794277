import React from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { toggleSubscribeToNewsletterModalVisibility } from "../../redux/features/commonSlice";

export const NewsletterBar = () => {
  const dispatch = useDispatch();

  const onJoinNewsletter = () => {
    dispatch(toggleSubscribeToNewsletterModalVisibility(true));
  };
  
  return (
    <div className="newsletter-bar">
      <Link className="newsletter-bar__button" onClick={onJoinNewsletter}>
        Join Our Newsletter
      </Link>
    </div>
  );
};
