import React, { createContext, useContext, useEffect, useState } from 'react';
import api from '../api';

const MovieShotsContext = createContext(null);
const IS_TESTNET = process.env.REACT_APP_IN_TESTNET_MODE === 'true';

const populateContractData = async (movie) => {
  if (!movie?.movieStatus || !movie?.contractAbiName) {
    return movie;
  }

  try {
    let discountPrice1 =
      movie.discountPrice1 === '0.0' ? null : movie.discountPrice1;
    let discountPrice2 =
      movie.discountPrice2 === '0.0' ? null : movie.discountPrice2;

    return {
      ...movie,
      discountPrice1,
      discountPrice2,
    };
  } catch (e) {
    console.error(e);
    return movie;
  }
};

export const useMovieShotsContext = () => useContext(MovieShotsContext);

export const MovieShotsContextProvider = ({ children }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [movies, setMovies] = useState([]);
  const [ethPrice, setEthPrice] = useState(null);

  const value = {
    isLoading,
    error,
    movies,
    ethPrice,
  };

  useEffect(() => {
    setIsLoading(true);
    const movieStatus = IS_TESTNET ? '-2' : '-1';
    const movieData = api.getCollectionsOnly(movieStatus);
    const ethPriceData = api.getWeb3Data();

    Promise.all([movieData, ethPriceData])
      .then((response) => {
        setEthPrice(response[1]?.ethPrice?.ethereum);
        return response;
      })
      .then((response) =>
        Promise.all(
          response[0]
            .sort(
              (a, b) =>
                new Date(a.createdAt).getTime() -
                new Date(b.createdAt).getTime()
            )
            .map(populateContractData)
        )
      )
      .then(setMovies)
      .catch(setError)
      .finally(() => setIsLoading(false));
  }, []);

  return (
    <MovieShotsContext.Provider value={value}>
      {children}
    </MovieShotsContext.Provider>
  );
};
