import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import Arrow from '../../assets/images/icons/arrow.svg';
import DecorativeElement from '../../assets/images/icons/M.svg';
import { SocialIcons } from '../../components/SocialIcons';
import { Logo } from '../../components/Logo';
import api from '../../api';
import { animateScroll as scroll } from 'react-scroll';

export const Footer = () => {
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const [fullname, setFullname] = useState('');
  const [email, setEmail] = useState('');

  const onInputFocus = () => {
    setError(null);
    setSuccess(null);
  };

  const onNameUpdate = (evt) => {
    setFullname(evt.target.value);
  };

  const onEmailUpdate = (evt) => {
    setEmail(evt.target.value);
  };

  const onSubmit = async (evt) => {
    if (fullname.length < 1) {
      evt.preventDefault();
      setError(`Name is required!`);
    } else if (email.length < 1) {
      evt.preventDefault();
      setError(`E-mail is required!`);
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email)) {
      evt.preventDefault();
      setError(`Invalid e-mail address!`);
    } else {
      try {
        evt.preventDefault();
        await api.subscribe(fullname, email);
        setSuccess("You're signed-up! Thanks for joining.");
      } catch (err) {
        if (err?.response?.data?.message) {
          setError(err.response.data.message);
        }
        console.log(err);
      }
    }
  };

  return (
    <footer className="footer-wrapper">
      <div className="footer container">
        <img className="decor" src={DecorativeElement} alt="m letter" />
        <div className="footer__section footer__section--vertical">
          <Logo className="footer__logo" mode="white" />
          <p className="footer-newsletter__text">
            Stay in the loop and join our exclusive mailing list
          </p>
          <div>
            <form className="footer-newsletter__form">
              <div className="footer-newsletter__input-wrapper">
                <label>
                  <div
                    className="footer-newsletter__input-content"
                    onClick={onInputFocus}
                  >
                    <input
                      type="text"
                      placeholder="Your name"
                      onInput={onNameUpdate}
                      value={fullname}
                    />
                  </div>
                </label>
                <label>
                  <div
                    className="footer-newsletter__input-content"
                    onClick={onInputFocus}
                  >
                    <input
                      type="text"
                      placeholder="Your email address"
                      onInput={onEmailUpdate}
                      value={email}
                    />
                  </div>
                </label>
                <button
                  className="footer-newsletter__submit"
                  onClick={onSubmit}
                >
                  &rarr;
                </button>
              </div>
              {success && (
                <p className="footer-newsletter__success">
                  {success}
                </p>
              )}
              {error && (
                <p className="footer-newsletter__error">
                  {error} Please check your input.
                </p>
              )}
              <p className="footer-newsletter__consent">
                Signing up indicates your consent to our{' '}
                <a
                  href="/terms-and-conditions"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Terms and Conditions
                </a>{' '}
                and{' '}
                <a
                  href="/data-protection"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Data Protection
                </a>
              </p>
            </form>
          </div>
          <ul className="footer__links">
            <li className="footer__link-wrapper">
              <Link className="footer__link link" to="/imprint">
                Imprint & Copyright
              </Link>
            </li>
            <li className="footer__link-wrapper">
              <Link className="footer__link link" to="/data-protection">
                Data Protection
              </Link>
            </li>
            <li className="footer__link-wrapper">
              <Link className="footer__link link" to="/terms-and-conditions">
                Terms and Conditions
              </Link>
            </li>
            <li className="footer__link-wrapper">
              © 2022, all rights reserved
            </li>
          </ul>
        </div>
        <div className="footer__section">
          <SocialIcons
            className="footer__links footer__links--socials"
            section="footer"
          />
          <div className="arrow-wrapper" onClick={() => scroll.scrollToTop()}>
            <img className="arrow" src={Arrow} alt="arrow" />
          </div>
        </div>
      </div>
    </footer>
  );
};
