import React from "react";
import cn from "classnames";

export const Loader = ({ className }) => {
  const circles = [...new Array(12)];
  const loaderClass = cn({
    loader: true,
    [className]: true,
  });
  return (
    <div className={loaderClass}>
      {circles.map(() => (
        <div className="loader-circle"></div>
      ))}
    </div>
  );
};
